import React from "react"
import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';
import DescargarAplicacion from '../components/pages/DescargarAplicacion'


const DescargarAplicacionPage = ({ pageContext, location }) => (
  <LayoutsComponent
    broker={pageContext.broker}
    layout={pageContext.layout}
    pageContext={pageContext}
    location={location}
    companyName={pageContext.company}
    render={() => (
      <>
        <SEO title={pageContext.title} />
        <DescargarAplicacion pageContext={pageContext} />
      </>
    )}
  />
)

export default DescargarAplicacionPage