import { useEffect, useState } from "react";
import { Grid, Typography, Snackbar } from "@material-ui/core";
import { TitleIcon, CardElevation, ButtonDegrade } from '@findep/mf-landings-core';
import FelicidadesApp from "../elements/FelicidadesApp.compilable";
import { css } from '@emotion/react';
import { navigate } from '../../helpers/queryNavigate';
import SwitchVideoIcon from '@material-ui/icons/SwitchVideo';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import { CataloguesService } from "../../services/LandingV4";
import Alert from '@material-ui/lab/Alert';

const DescargarApplicacion = ({ pageContext }) => {

    const id = !((typeof window != 'undefined') && sessionStorage.getItem('flujo') === null) ? (typeof window != 'undefined') ? JSON.parse(sessionStorage.getItem('flujo')) : '' : '';
    const { creditApplicationId } = id;  

    const [ qr, setQr ] = useState('');
    const [ errorservice, setErrorservice ] = useState(false);
    
    useEffect(() => {
        const catalogues = new CataloguesService(creditApplicationId);
        catalogues.getCustomerApp(creditApplicationId)
        .then((response) => {
            var reader = new FileReader();
            reader.readAsDataURL(response.data); 
            reader.onloadend = function() {
                var base64data = reader.result;                
                setQr(base64data);
            }
        })
      .catch(error => {
        setErrorservice(true);
        console.error(error);
      })
    }, [creditApplicationId]);

    const { company } = pageContext;

    const paddingGridCss = css`
        padding: 1rem;
    `

    const centerElementCss = css`
        text-align: center;
    `
    const paragraphCss = css`
        color: rgba(0, 0, 0, 0.87);
        font-size: 1.1rem;
    `   
    const imgSizeCss = css`
        width: 45%;
    `

    const nextStep = () => {
        navigate("/");
    }

    return (
        <Grid container justify="center">
            <Grid container justify="center">
                <Grid item css={centerElementCss}>
                    <TitleIcon icon={SwitchVideoIcon} title="Descarga la Aplicación" textColor />
                </Grid>
            </Grid>
            
            <Grid container justify="center" >
                <Grid item xs={12} md={6} css={paddingGridCss}>
                    <CardElevation>
                        <Typography css={paragraphCss} >
                            Descarga nuestra aplicación en la siguiente liga:
                        </Typography>
                            <FelicidadesApp company={company} />
                        <Typography css={paragraphCss}>                            
                            Una vez descargada la aplicación, ábrela y escanea el siguiente código. Si estás haciéndolo desde tu dispositivo móvil y no puedes escanearlo, guárdalo como imagen ya que se te pedirá en breve.
                        </Typography>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                            >
                                <img src={qr} alt="Código QR" css={imgSizeCss}/>
                                <ButtonDegrade 
                                    id="descargar-aplicacion-button"
                                    textButton="TERMINAR Y CERRAR"
                                    icon={CameraEnhanceIcon}
                                    onClick={nextStep}
                                />
                            </Grid>
                    </CardElevation>
                 </Grid>
            </Grid>

            <Snackbar open={errorservice} autoHideDuration={6000} onClose={() => setErrorservice(false)}>
                <Alert variant="filled" severity="warning" onClose={() => setErrorservice(false)}>
                    Lo sentimos, por el momento el servicio no se encuentra disponible
                </Alert>
            </Snackbar>

        </Grid>
    );
}
 
export default DescargarApplicacion;